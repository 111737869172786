// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe {
  display: block; /* iframes are inline by default */
  height: 100vh; /* Set height to 100% of the viewport height */
  width: 100vw; /* Set width to 100% of the viewport width */
  border: none; /* Remove default border */
}`, "",{"version":3,"sources":["webpack://./src/custom_c.scss"],"names":[],"mappings":"AAAA;EACE,cAAA,EAAA,kCAAA;EACA,aAAA,EAAA,8CAAA;EACA,YAAA,EAAA,4CAAA;EACA,YAAA,EAAA,0BAAA;AACF","sourcesContent":["iframe {\n  display: block; /* iframes are inline by default */\n  height: 100vh; /* Set height to 100% of the viewport height */\n  width: 100vw; /* Set width to 100% of the viewport width */\n  border: none; /* Remove default border */\n  // background: lightyellow; /* Just for styling */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
