import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Home from "./Home";
import Login from "./Login";
import authService from "./utils/authService";
import {
  getToken,
  removeToken,
  getRefreshToken,
  removeRefreshToken,
  setRefreshToken,
  isAuthenticated,
} from "./utils/auth";
import Register from "./Register";
import "./custom_c.scss";
import NotLoggedInPage from "./NotLoggedInPage";
import Distributor from "./components/Distributor";
import Stores from "./components/Stores";
import Reports from "./components/Reports";
import AllRecords from "./components/AllRecords";
import ConflictRecords from "./components/ConflictRecords";
import FileStatus from "./components/FileStatus";
import AdminControl from "./components/AdminControl";
import ApprovalTray from "./components/ApprovalTray";
const App = () => {
  const [tokenExpired, setTokenExpired] = useState(false);
  const BASE_URL = process.env.API_LINK;

  useEffect(() => {
    // Function to check token expiration
    const checkTokenExpiration = () => {
      try {
        const token = getToken();
        // If no token is present, consider it expired
        if (!token) {
          setTokenExpired(true);
          return;
        }

        // Decode the token to get expiration time
        const decodedToken = authService.decodeToken(token);
        const currentTime = Math.floor(Date.now() / 1000);

        // If the token is expired, set the state to true
        if (decodedToken.exp < currentTime) {
          setTokenExpired(true);
          console.log("expired");
          // removeToken();
          // removeRefreshToken();
          handleTokenRefresh();
        } else {
          // Calculate the remaining time before token expiration
          const timeLeftInSeconds = decodedToken.exp - currentTime;
          console.log(timeLeftInSeconds);
        }
      } catch (error) {
        // If there's an error decoding the token, consider it expired
        console.log("Error decoding token:", error);
        setTokenExpired(true);
      }
    };

    // Call the function to check token expiration
    checkTokenExpiration();
  }, []); //

  const handleLogout = () => {
    removeToken();
    removeRefreshToken();
  };

  const handleTokenRefresh = async () => {
    const refreshToken = getRefreshToken();
    console.log(refreshToken);
    console.log(refreshToken);
    console.log(refreshToken);
    if (!refreshToken) {
      handleLogout();
      return;
    }

    try {
      const newToken = await authService.refreshToken(refreshToken);
      setRefreshToken(newToken);
    } catch (error) {
      console.error("Token refresh failed:", error);
      handleLogout();
    }
  };
  return (
    <Routes>
      <Route path="/Login" element={<Login />} />
      <Route path="/Register" element={<Register />} />
      <Route path="/" element={<Login />} />
      {!isAuthenticated() ? (
        <Route path="/*" element={<NotLoggedInPage />} />
      ) : (
        <>
          {/* <Route path="/a/*" element={<Distributor />} /> */}
          {
            localStorage.getItem("role") === "maker" ?
              <>
                <Route path="/stores" element={<Stores />} />
                <Route path="/FileStatus" element={<FileStatus />} />
              </>
              : <></>
          }
          {
            localStorage.getItem("role") === "checker" ?
              <>
                <Route path="/ApprovalTray" element={< ApprovalTray />} />

              </>
              : <></>
          }
          <Route path="/dashboard" element={<Stores />} />
          <Route path="/AllRecords" element={<AllRecords />} />
          <Route path="/ConflictRecords" element={<ConflictRecords />} />

          <Route path="/reports" element={<Reports />} />
          <Route path="/AdminControl" element={<AdminControl />} />
        </>
      )}
    </Routes>
  );
};

export default App;
